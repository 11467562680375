const faqs = [
  {
    question: "1. ¿Cuánto cuesta un seguro de viaje a Europa desde Chile?",
    answer: (
      <div>
        <p>
          El costo de un seguro de viaje a Europa desde Chile puede variar
          dependiendo de la duración del viaje, la edad del viajero y las
          coberturas incluidas. En general, los precios pueden oscilar entre los
          $50 y $150 USD por semana de viaje. Es recomendable cotizar diferentes
          opciones para encontrar la que mejor se adapte a tu presupuesto.
        </p>
      </div>
    ),
  },
  {
    question: "2. ¿Qué debe cubrir el seguro de viaje para Europa?",
    answer: (
      <div>
        <p>
          El seguro de viaje para Europa debe cubrir como mínimo 30.000 euros en
          gastos médicos, incluyendo atención médica de emergencia y
          repatriación. Además, es recomendable que incluya cobertura por
          cancelación de viajes, pérdida de equipaje y asistencia legal en caso
          de ser necesario.
        </p>
      </div>
    ),
  },
  {
    question: "3. ¿Es obligatorio el seguro de viaje para ingresar a Europa?",
    answer: (
      <div>
        <p>
          Sí, para ingresar a los países del Espacio Schengen es obligatorio
          contar con un seguro de viaje que cumpla con los requisitos
          establecidos, incluyendo una cobertura mínima de 30,000 euros en
          gastos médicos. Sin este seguro, podrías ser denegado en el control de
          fronteras.
        </p>
      </div>
    ),
  },
  {
    question: "4. ¿Puedo contratar un seguro de viaje si ya estoy en Europa?",
    answer: (
      <div>
        <p>
          En general, se recomienda contratar el seguro de viaje antes de salir
          de Chile. Sin embargo, algunas aseguradoras permiten la contratación
          del seguro una vez iniciado el viaje, aunque las condiciones pueden
          variar y es posible que se apliquen períodos de espera antes de que
          las coberturas entren en vigor. Es importante consultar las políticas
          específicas de cada aseguradora.
        </p>
      </div>
    ),
  },
];

export default faqs;
